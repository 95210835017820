import * as React from "react";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import H3 from "../utils/H3";
import Paragraph from "../utils/Paragraph";
import ExpandBox from "../components/ExpandBox/ExpandBox";
import { caseStudies } from "../data/case-studies";
import { reports } from "../data/reports";
import Button from "../utils/Button";
import Seo from "../components/seo";
import Favicon from "../images/MRC LOGO.png";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const ResourcesPage = () => {
  const cases = caseStudies.data.cases;
  return (
    <Layout>
      <Seo title="Resources" url="https://migrantresilience.org/resources" />
      <div className="w-full flex flex-col justify-center items-center relative">
        <Banner src="/images/resources-banner-new.jpg" position="object-top" />
      </div>
      <div className="bg-white py-12 w-full flex flex-col justify-center items-center lg:px-28 md:px-12 px-4">
        <div className="w-full flex flex-row justify-between items-center relative z-0 mb-4">
          <h1 className="text-left text-darkblue md:text-4xl text-2xl font-bold uppercase lg:w-max w-full">
            Reports: Trends and Insights
          </h1>
          <img
            src="/images/dot-pattern.png"
            className="absolute top-0 -left-8 w-80 object-contain opacity-80 -z-1"
          ></img>
          {/* <img src="/images/blue-dots.png" className="absolute -top-8 right-20 w-80 object-contain opacity-80 -z-1"></img> */}
          {/* <Button type="externalLink" href="https://jan-sahas-website.s3.ap-south-1.amazonaws.com/MRC+Brochure.pdf">To know more about MRC, click here</Button> */}
        </div>
        <div className="grid md:grid-cols-3 grid-cols-2 grid-rows-auto md:gap-12 gap-6 mt-6">
          {reports?.map((report, key) => (
            <Report report={report} key={key}></Report>
          ))}
        </div>
      </div>
      <div className="bg-sectionBlue py-20 w-full flex flex-col justify-center items-center overflow-hidden">
        <div className="w-full flex flex-row justify-between items-center relative z-0 lg:px-28 md:px-12 px-4 mb-12">
          <h1 className="text-left text-darkblue md:text-4xl text-2xl font-bold uppercase lg:w-max w-full">
            Stories of Change
          </h1>
          <img
            src="/images/blue-dots.png"
            className="absolute top-0 left-20 w-80 object-contain opacity-80 -z-1"
          ></img>
        </div>

        {cases?.map((study, key) => {
          if (study.id % 2 == 0) return <Case study={study} key={key} />;
          else return <InvertedCase study={study} key={key} />;
        })}
      </div>
    </Layout>
  );
};
const Case = (props) => {
  const { study } = props;
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col justify-start items-center relative z-0 lg:px-28 md:px-12 px-4 md:mb-0 mb-12 last:mb-0">
      <div className="lg:grid lg:grid-rows-1 lg:grid-cols-2 gap-0 flex flex-col">
        <img
          src={study.image}
          className={`w-full ${
            open ? "lg:h-full" : "lg:h-[500px]"
          } md:h-[450px] h-[270px] object-cover object-top`}
        />
        <div className="w-full lg:pl-8 flex flex-col justify-center items-start lg:py-12 py-8">
          <h3 className="font-bold text-darkblue mb-4 text-2xl mt-0">
            {study.name}
          </h3>
          {study.description.map((row, key) =>
            row.id <= 1 ? (
              <p className="text-paragraph font-normal text-sm last:mb-0 mb-2">
                {row.paragraph}
              </p>
            ) : (
              <AnimatePresence initial={false}>
                {open && (
                  <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { height: "auto", opacity: 1 },
                      collapsed: { height: "0px", opacity: 0 },
                    }}
                    transition={{
                      duration: 0.6,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                    className="last:mb-0 mb-2"
                  >
                    <motion.p
                      variants={{
                        collapsed: { opacity: 0.8 },
                        open: { opacity: 1 },
                      }}
                      transition={{ duration: 0.3 }}
                      className="text-paragraph font-normal text-sm last:mb-0"
                    >
                      {row.paragraph}
                    </motion.p>
                  </motion.section>
                )}
              </AnimatePresence>
            )
          )}
          {study.description.length > 1 ? (
            <div
              onClick={() => {
                setOpen(!open);
              }}
              className="px-4 py-2 mt-4 shadow-xl hover:shadow-xl hover:shadow-darkblue/30 shadow-darkblue/20 font-bold rounded-3xl flex justify-center items-center bg-darkblue text-white text-sm cursor-pointer transition-all duration-300 transform hover:-translate-y-1"
            >
              {open ? "read less" : "read more"}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
const InvertedCase = (props) => {
  const { study } = props;
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col justify-start items-center relative z-0 lg:px-28 md:px-12 px-4 md:mb-0 mb-12 last:mb-0">
      <div className="lg:grid lg:grid-rows-1 lg:grid-cols-2 gap-0 flex flex-col-reverse">
        <div className="w-full lg:pr-8 flex flex-col justify-center items-start lg:py-12 py-8">
          <h3 className="font-bold text-darkblue mb-4 text-2xl mt-0">
            {study.name}
          </h3>
          {study.description.map((row, key) =>
            row.id <= 1 ? (
              <p className="text-paragraph font-normal text-sm last:mb-0 mb-2">
                {row.paragraph}
              </p>
            ) : (
              <AnimatePresence initial={false}>
                {open && (
                  <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { height: "auto", opacity: 1 },
                      collapsed: { height: "0px", opacity: 0 },
                    }}
                    transition={{
                      duration: 0.6,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                    className="last:mb-0 mb-2"
                  >
                    <motion.p
                      variants={{
                        collapsed: { opacity: 0.2 },
                        open: { opacity: 1 },
                      }}
                      transition={{ duration: 0.3 }}
                      className="text-paragraph font-normal text-sm last:mb-0"
                    >
                      {row.paragraph}
                    </motion.p>
                  </motion.section>
                )}
              </AnimatePresence>
            )
          )}
          {study.description.length > 1 ? (
            <div
              onClick={() => {
                setOpen(!open);
              }}
              className="px-4 py-2 mt-4 shadow-xl hover:shadow-xl hover:shadow-darkblue/30 shadow-darkblue/20 font-bold rounded-3xl flex justify-center items-center bg-darkblue text-white text-sm cursor-pointer transition-all duration-300 transform hover:-translate-y-1"
            >
              {open ? "read less" : "read more"}
            </div>
          ) : (
            ""
          )}
        </div>
        <img
          src={study.image}
          className={`w-full ${
            open ? "lg:h-full" : "lg:h-[500px]"
          } md:h-[450px] h-[270px] object-cover object-top`}
        />
      </div>
    </div>
  );
};
const Report = (props) => {
  return (
    <a
      href={props.report.link}
      id={"report" + props.key}
      target="_blank"
      rel="nofollow"
      className="w-full focus:outline-none flex flex-col justify-center items-center hover:shadow-2xl hover:scale-105 transform transition-all duration-300 ease cursor-pointer shadow-darkblue"
    >
      <img
        src={props.report.image}
        className="w-full h-full object-contain object-cover"
      ></img>
    </a>
  );
};
export default ResourcesPage;
