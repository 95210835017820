export const reports = [
  {
    id: 1,
    link: "/internal-migration-in-asia.pdf",
    image: "/images/resources/Internal-migration-in-Asia-1.jpg",
  },
  {
    id: 2,
    link: "/regional-consultation-on-internal-migration-in-asia.pdf",
    image:
      "/images/resources/Regional_Consultation_on_Internal_Migration_in_Asia-1.jpg",
  },
  {
    id: 3,
    link: "/ensuring-safe-migration-in-asia-series-part-1.pdf",
    image: "/images/resources/Covid_24-july-1.jpg",
  },
  {
    id: 4,
    link: "/ensuring-safe-migration-in-asia-series-part-2.pdf",
    image: "/images/resources/Climate-Change_24-july-1.jpg",
  },
  {
    id: 5,
    link: "/ensuring-safe-migration-in-asia-series-part-3.pdf",
    image: "/images/resources/Labour-laws_24-july-1.jpg",
  },
  {
    id: 6,
    link: "/ensuring-safe-migration-in-asia-series-part-4.pdf",
    image: "/images/resources/Social-Security_24-july-1.jpg",
  },
  {
    id: 7,
    link: "https://jan-sahas-website.s3.ap-south-1.amazonaws.com/publications/Voices+of+the+invisible+citizens_April_2020_JS.pdf",
    image: "/images/report3.jpg",
  },
  {
    id: 8,
    link: "https://jan-sahas-website.s3.ap-south-1.amazonaws.com/publications/upload-1624283517.9970686.ONE_YEAR_OF_COVID-19.pdf",
    image: "/images/report1.jpg",
  },
  {
    id: 9,
    link: "https://jan-sahas-website.s3.ap-south-1.amazonaws.com/publications/upload-1610541523.3742168.JAN_SAHAS_COVID19_Relief_Response__Stories_of_Resilience__Hope_July2020.pdf",
    image: "/images/report2.jpg",
  },
  {
    id: 10,
    link: "https://jan-sahas-website.s3.ap-south-1.amazonaws.com/publications/Report+Low+Res+15th+December+2021.pdf",
    image: "/images/report-4.jpg",
  },
];
