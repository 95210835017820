export const caseStudies = {
  data: {
    cases: [
      {
        id: 1,
        name: "Preeti Bai",
        description: [
          {
            id: 1,
            paragraph:
              "Preeti Bai is a young woman aged 26 who found herself migrating to Noida, Uttar Pradesh in search of better opportunities for herself and her family. Hailing from the district of Damoh in Madhya Pradesh, she had little hope of finding work in her hometown or of receiving adequate healthcare for her ailing husband. Thus, with two young daughters in tow, Preeti and her husband set out to Noida in hopes of finding a better life.",
          },
          {
            id: 2,
            paragraph:
              "It was at a construction site in Noida where Preeti happened to attend an awareness session organised by MRC. Here, she learned about the benefits of having a BOCW (Building and Other Construction Workers) card, something that she had never heard of back in Damoh. She was also unaware of the social security entitlements that came with it. But with the help of MRC, Preeti was able to register for the BOCW card and gather all the necessary documents to do so.",
          },
          {
            id: 3,
            paragraph:
              "On the 5th of November in 2021, Preeti was delighted to receive her BOCW card along with a cash benefit of Rs 3000 from the UP-State Government. The MRC team also provided her with ration support for a month.",
          },
          {
            id: 4,
            paragraph: `"It is comforting to know that with the BOCW card, I can avail health and pension benefits, and education benefits for my children. The ration support provided by MRC helped us tide over the week's work stopped during the pollution ban in NCR," said Preeti.`,
          },
          {
            id: 5,
            paragraph: `"Moving to the city was not an easy decision for me and my family. Working at construction sites is physically exhausting and not always safe," she added.`,
          },
          {
            id: 6,
            paragraph: `Despite the hardships faced by Preeti and her family, she remained determined to make the most of the opportunities available to her. With the help of MRC and the benefits of the BOCW card, Preeti was able to secure a better prospect for herself and her loved ones.`,
          },
        ],
        image: "/images/case-study-1.jpg",
      },
      {
        id: 2,
        name: "Rani Devi",
        description: [
          {
            id: 1,
            paragraph:
              "Rani Devi hails from Bhagalpur, Bihar. Her family owns no land, and for years her husband worked as a labourer in and around their village. Two years ago, due to financial constraints, her husband had to take a loan for his sister’s wedding. But they soon realised that the wages he was making in Bhagalpur wouldn't be enough to pay back the loan. So Rani and her husband decided to migrate to Haryana with their four children, hoping to find better-paying work in Gurugram.",
          },
          {
            id: 2,
            paragraph:
              "They enrolled their two elder children in school and found work at a Tata construction site in Gurugram. Life was tough, but they were getting by and even managed to accumulate some savings to pay back the loans. However, the lockdown during the second wave and high levels of air pollution in Gurugram caused yet another setback for them.",
          },
          {
            id: 3,
            paragraph:
              "A few months ago, the MRC team visited their construction site and organised an awareness session for the workers on government social security schemes they were entitled to. When they met Rani, they realised that she and her husband had not been registered for E-Shram yet. So, they helped them both with their registration process and also provided them with one month's worth of ration to help them continue saving for the loan.",
          },
          {
            id: 4,
            paragraph:
              "Rani is now more aware of her rights as a worker and knows how to apply for social security schemes to support her family. Thanks to the MRC team's support, she feels more confident about her family's future despite the challenges they have faced so far.",
          },
        ],
        image: "/images/case-study-2.jpg",
      },
      {
        id: 3,
        name: "Tarabai",
        description: [
          {
            id: 1,
            paragraph:
              "Tarabai Gahir is a migrant worker from Baloda Bazaar district, Chhattisgarh. She stays with her husband, Topram Gahir and mother -in-law. Owing to their vulnerable financial condition, the family migrates to Basti, Uttar Pradesh ever year to work in the brick kilns. ",
          },
          {
            id: 2,
            paragraph:
              "During the lockdown imposed this year, the family had to return from the brick kilns without making substantial income. The family quarantined for 14 days in a government school in their village before they could return to their home. Tarabai was pregnant during this time period and because of unavailability of proper food and care, her health deteriorated and she became quite weak. The MRC team had met the family before they migrated and had supported them in registering for their Ayushman card which Tarabai received in July 2021. This came as a major help to the family during Tarabai's pregnancy.",
          },
          {
            id: 3,
            paragraph:
              "On 18th September, Tarabai delivered a baby in Bharati Hospital Saraipalli. The cost of delivery was borne by the government as a benefit under the Ayushman card created for the family. The new born was also very weak and not in good health. His treatment was also done free of cost as a benefit under the Ayushman card. The family shared, “We are already in so much debt, if we had to bear the expenses of the delivery and child care, we would never have been able to manage and would have to keep migrating to pay of all the debts. We are very thankful to the team of MRC for supporting us in getting our right timely.” Currently, Tarabai and the baby are doing better but the baby’s treatment is still ongoing. The family does not plan to migrate till their child’s health improves.",
          },
        ],
        image: "/images/case-study-3.jpg",
      },
      {
        id: 4,
        name: "Amrut Kalyan",
        description: [
          {
            id: 1,
            paragraph:
              "Hailing from the Solapur district of Maharashtra, Amrut Kalyan has been migrating in search of work for years now. Around 4 years ago, Amrut moved to Pune with his wife and 6 children.",
          },
          {
            id: 2,
            paragraph:
              "He is the only earning member in his family, and with 7 mouths to feed, their financial situation has never been stable. Amrut is an unskilled worker and every day lines up at a labour chowk with hundreds of other workers hoping to be picked up by some contractor for work. His household runs on the daily wages he manages to earn.",
          },
          {
            id: 3,
            paragraph:
              "There are days when they have to buy ration on borrowed money. During the COVID-19 pandemic, Amrut and his family decided to stay back in Pune, but the lack of work and wages made this a very difficult time period for them. They burnt through all their savings. Once the lockdown started being lifted, work was still hard to come by.",
          },
          {
            id: 4,
            paragraph:
              "Amrut mentioned seeing a dip in the daily wages he earns now. When the MRC team met Amrut, he was overworked and still not being able to make enough money to support his family. The team took down his name for the ration distribution list and within a day, he was supported with a ration kit that helped his family tide over the next month.",
          },
          {
            id: 5,
            paragraph:
              "For daily wage workers like Amrut, such small efforts of support goes a long way.",
          },
        ],
        image: "/images/case-study-4.jpg",
      },
    ],
  },
};
